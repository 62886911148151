import { Footer } from "./components/footer/Footer";
import "./App.scss";

import { Contactus } from "./pages/Contactus";

function App() {
  return (
    <div className="App">
      <Contactus />
      <Footer />
    </div>
  );
}

export default App;
