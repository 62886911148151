import React from "react";
import "./style.scss";
import logo from "../../assets/images/logo.png";
import { AiFillFacebook, AiFillLinkedin } from "react-icons/ai";
import { FiInstagram } from "react-icons/fi";
import { FaYoutubeSquare } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { Container, Row, Col } from "react-bootstrap";

export const Footer = () => {
  return (
    <div className="footer">
      <Container>
        <Row style={{alignItems: 'center'}}>
          <Col sm={12} md={4} className="collapse-to-center">
            <img className="logo" src={logo} alt="Innotech Marine" width={220} />
          </Col>
          <Col sm={12} md={7} className="follow-us collapse-to-center mt-4 mt-md-0">
            <span className="d-none d-lg-inline mx-2 copy-text">Follow us:</span>
            <span className="icons">
              <a href="https://www.facebook.com/innotechmarine.co">
                <AiFillFacebook />
              </a>
              <a href="https://www.instagram.com/innotechmarine.co">
                <FiInstagram />
              </a>
              <a href="https://x.com/innotechmarine">
                <FaXTwitter />
              </a>
              <a href="https://www.youtube.com/@innotechmarine">
              <FaYoutubeSquare />
              </a>
              <a href="https://www.linkedin.com/company/innotechmarine">
                <AiFillLinkedin />
              </a>
            </span>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
