/* global grecaptcha */
import React from "react";
import "./contact.scss";
import { Container, Row, Col, Button, Alert, Form } from "react-bootstrap";

const CONTACT_FORM_URL = 'https://api.innotechmarine.co/contact_us';

export const Contact = () => {
  const [isEmailSubmitted, setEmailSubmitted] = React.useState(false);
  const [submissionError, setSubmissionError] = React.useState(null);

  const handleSubmit = React.useCallback((e) => {
    e.preventDefault();
    
    setEmailSubmitted(true);

    grecaptcha.ready(function() {
      grecaptcha.execute('6LcYvVYoAAAAABNkBM8DhGGKgIoEX3v9Yg_Me5Bz', {action: 'submit'}).then(function(token) {
        const body = [...e.target.querySelectorAll('input, textarea')].reduce((o, input) => {
          o[input.name] = input.value;
          return o;
        }, {token});

        window.fetch(CONTACT_FORM_URL, {
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
          }
        })
        .then(async (resp) => {
          if (resp.status === 200) {
            setSubmissionError(null);
          } else {
            const json = await resp.json()
            setSubmissionError(`There was a problem submitting this email. Please try again later. (${resp.status}: ${json.message}).`)
            console.log(resp)
          }
        })
        .catch((e) => {
          setSubmissionError('There was a problem submitting this form. Please try again later.');
          console.log(e)
        })
        .finally(() => {
          setEmailSubmitted(true);
        })
      });
    });

    return false;
  }, []);

  const contactForm = React.useMemo(() => {
    if (submissionError) {
      return (
        <Alert variant="warning" className="mt-1">
          {submissionError}
        </Alert>
      )  
    }

    if (isEmailSubmitted) {
      return (
        <Alert variant="light" className="mt-1">
          Thank you for your email. We'll be in touch soon!
        </Alert>
      )
    }

    return (
      <Form method="post" onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="full_name">Full name:</Form.Label>
          <Form.Control id="full_name" name="full_name" type="text" size="lg" required />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="email_address">Email address:</Form.Label>
          <Form.Control id="email_address" name="email_address" type="email" itemType="email" required size="lg" />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="phone_number">Phone number:</Form.Label>
          <Form.Control id="phone_number" name="phone_number" type="phone" size="lg" />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="message">Message:</Form.Label>
          <Form.Control id="message" name="message" as="textarea" size="lg" style={{resize: 'none'}} required />
        </Form.Group>
        <Button variant="primary" type="submit" size="lg" style={{minWidth: 150}}>
          Submit
        </Button>
        <small className="text-muted d-block mt-4" style={{opacity: 0.5}}>
          This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.
        </small>
      </Form>
    );
  }, [handleSubmit, isEmailSubmitted, submissionError]);


  return (
    <div className="contactcontainer" id="contact-us">
      <Container>
        <Row>
          <Col xs={12} md={4}>
            <h2 style={{fontWeight: 900, fontSize: '3rem'}}>Contact us.</h2>
            <p className="copy-text" style={{margin: '2rem 0'}}>
              To contact the Innotech Marine team, please fill out the contact form with your inquiry and we'll be in touch soon.
            </p>
          </Col>
          <Col xs={12} md={{span: 7, offset: 1}}>
            {contactForm}
          </Col>
        </Row>
      </Container>
    </div>
  );
};
