import React from "react";
import { Herosection } from "../components/herosection/Herosection";
import { Contact } from "../components/contact/Contact";

export const Contactus = () => {
  return (
    <div>
      <Herosection />
      <Contact />
    </div>
  );
};
