/* global grecaptcha */
import React from "react";
import "./style.scss";
import logo from "../../assets/images/logo.png";
import icast24 from "../../assets/images/ICAST-Logo-Stacked-2024-400x140-1.png";
import icast24_nps from "../../assets/images/icast24_nps.png";
import { Button, InputGroup, Form, Container, Row, Col, Alert } from "react-bootstrap";

const MAILING_LIST_URL = 'https://api.innotechmarine.co/signup';

export const Herosection = () => {
  const [isEmailSubmitted, setEmailSubmitted] = React.useState(false);
  const [submissionError, setSubmissionError] = React.useState(null);

  const handleSubmit = React.useCallback((e) => {
    e.preventDefault();

    setEmailSubmitted(true);
    
    grecaptcha.ready(function() {
      grecaptcha.execute('6LcYvVYoAAAAABNkBM8DhGGKgIoEX3v9Yg_Me5Bz', {action: 'submit'}).then(function(token) {
        const body = [...e.target.querySelectorAll('input')].reduce((o, input) => {
          o[input.name] = input.value;
          return o;
        }, {token});

        window.fetch(MAILING_LIST_URL, {
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
          }
        })
        .then(async (resp) => {
          if (resp.status === 200) {
            setSubmissionError(null);
          } else {
            const json = await resp.json()
            setSubmissionError(`There was an error signing your email up (${resp.status}: ${json.message}).`)
            console.log(resp)
          }
        })
        .catch((e) => {
          setSubmissionError('Cannot subscribe you to the mailing list. Please try again later.');
          console.log(e)
        })
        .finally(() => {
          setEmailSubmitted(true);
        })
      });
    });

    return false;
  }, []);

  const mailingListForm = React.useMemo(() => {
    if (submissionError) {
      return (
        <Alert variant="warning" className="mt-1">
          {submissionError}
        </Alert>
      )  
    }

    if (isEmailSubmitted) {
      return (
        <Alert variant="light" className="mt-1">
          You're all set! We'll give you periodic updates on Innotech Marine products.
        </Alert>
      )
    }

    return (
      <Form onSubmit={handleSubmit}>
        <InputGroup size="lg" style={{margin: '0.5rem 0'}}>
          <Form.Control type="email" name="email_address" placeholder="Email address" inputMode="email" required />
          <Button type="submit" variant="primary" style={{padding: '0.8em 2em', borderRadius: 6, marginLeft: -6}}>
            Notify Me!
          </Button>
        </InputGroup>
      </Form>
    );
  }, [handleSubmit, isEmailSubmitted, submissionError]);

  return (
    <div className="hero">
      <div className="herobg">
        <div className="herodata">
          <Container>
            <Row>
              <Col>
                <div className="logo">
                  <img src={logo} alt="Innotech Marine" width={280} />
                </div>
                <div className="title">
                  Great things are
                  <div>
                    <span className="accent">coming soon.</span>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={5}>
                <p className="copy-text">
                  Innotech Marine is developing a highly integrated suite of consumer products and
                  technologies specifically for <strong>fisherman</strong>. Stay
                  tuned for updates as we launch!
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                Join our mailing list to stay updated!
              </Col>
              <Col sm={12} md={8} lg={5}>
                {mailingListForm}
              </Col>
            </Row>
            <Row  style={{ marginTop: '3em' }}>
              <Col>
                <p className="copy-text" xs={12} style={{textAlign: 'left', margin: '1em 0 1em 0'}}>
                  Follow us on social media as we debut at ICAST 2024!
                </p>
                <a href="https://icast2024.mapyourshow.com/8_0/exhview/index.cfm?selectedBooth=1342" target="_blank" rel="noopener noreferrer">
                  <img src={icast24} alt="ICAST24" style={{marginRight: '1em'}} />
                  <img src={icast24_nps} alt="ICAST24 NPS" width={130}/>
                </a> 
                
              </Col>
            </Row>
            <Row>
              <Col className="copy-text" xs={12} style={{textAlign: 'center', margin: '8em 0 2em 0'}}>
                Have a question? <a href="#contact-us">Contact us</a>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
};
